* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul,li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

@import './variables';

body, html, #root {
    height: 100%;
    min-height: 100%;
    min-height: stretch;
    font-family: $default-font;
    color: $text;
    font-size: 14px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

body .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
body .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px;
}
  
