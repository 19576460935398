@import 'styles/_variables';

.logoWrapper {
    padding: 14px 0;
    display: flex;
    justify-content: center;
}

.menu {
    padding-top: 34px;
}

.menuItemLink {
    padding: 20px 24px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    transition: all .3s ease-in-out;
    color: $text;

    svg {
        transition: all .3s ease-in-out;

        rect,
        path {
            transition: all .3s ease-in-out;
        }
    }

    &:hover {
        color: $accent_blue;

        .notificationIcon,
        .generateIcon,
        .helpIcon {
            fill: $accent_blue;
        }

        .historyIcon {

            rect,
            path {
                stroke: $accent_blue;
            }
        }
    }
}

.menuItemLinkActive {
    color: $accent_blue;
    background-color: #fff;

    .notificationIcon,
    .generateIcon,
    .helpIcon {
        fill: $accent_blue;
    }

    .historyIcon {

        rect,
        path {
            stroke: $accent_blue;
        }
    }
}