@import 'styles/_variables';

.root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    color: $accent_blue;
    font-size: 64px;
    font-weight: 600;
}