@import "styles/_variables";

.root {
    height: 100%;
    display: grid;
    grid-template-columns: 260px 2fr;
    align-items: stretch;
}

.aside {
    background-color: $grey-1;
    padding: 10px;
}